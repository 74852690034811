/* Reset default margin and padding */
body,
h1,
h2,
h3,
p {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Basic styling for the entire app */
.App {
  width: 100vw;
  height: 100vh;
  overflow: hidden; /* Hide any content that exceeds the bounds */
  display: flex;
  flex-direction: column;
}

.App-header {
  background-color:  #0d47a1;
  min-height: 58px; /* Adjusted min-height to avoid overflow */
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Date-Section {
  width: 100%;
  height: 58px;
  background-color: rgba(217, 217, 217, 0.12);
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
}

.Date-Section .centered-date {
  margin: 0;
  text-align: left;
  padding-left: 20px;
}

.predictionCardsSection {
  flex: 1; /* Allow this section to grow and take available space */
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100vw;
  overflow-x: auto; /* Enable scrolling for content exceeding this section */
}

.p-title {
  font-size: 20px;
  padding-left: 20px;
}

.predictionCardsViewport {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc((100% - 40px) / 3 - 20px), 1fr)); /* Split into 3 parts */
  gap: 20px; /* Create space between cards */
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  overflow-x: auto; /* Enable horizontal scrolling */
}



.predictionCards {
  flex: 3 0 auto; /* Updated flex properties */
  flex-basis: 0;
  flex-grow: 2;
  margin-right: 20px; /* Adjust margin */
  margin-bottom: 20px; /* Create space between rows */
  padding: 10px;
  box-sizing: border-box; /* Ensure padding doesn't affect width */
  height: auto;
  width: 100%;
}


/* Hide the default scrollbar for webkit browsers */
/* ... (Your scrollbar styles remain the same) */


/* You can add more specific styles for different sections/components if needed */
/* Hide the default scrollbar for webkit browsers */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}