/* PredictionCard.css */

@font-face {
  font-family: 'BruelGroteska-Bold';
  src: url('./BreulGroteskA-Bold.ttf') format('truetype');
}




/* Import the specified font */
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');

/* PredictionCard component styles */
.PredictionCard {
  background-color: black;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  width: 340px; /* You can adjust the width as needed */
  height: 430px; /* You can adjust the height as needed */
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Prevent content overflow */
}

/* Container styles */
.Container {
  display: flex;
  width: 100%;
}

/* Team section styles */
.Home, .Away {
  flex-direction: column;
  width: 50%;
  font-family: 'PT Sans Narrow';
  border-radius: 10px;
}

/* Team logo and score styles */
.scorenlogo_home, .scorenlogo_away {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/* Location indicator styles */
.locationIndicator {
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  width: 100%;
  text-align: center;
  opacity: 0.5;
}

/* Scrollable viewport styles */
.scrollable-viewport {
  height: auto; /* Set the height based on your design */
  max-height: 31.2%;
  overflow-y: auto; /* Enable vertical scrolling */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 0; /* Added padding to adjust spacing */
}



/* Team details styles */
.teamDetails {
  display: flex;
  flex-direction: column;
}

.teamName {
  font-weight: bold; /* Optional: Set font weight for team name */
}

/* Score styles */
.scoreHome, .scoreAway {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.1rem;
  padding: 5px;
  min-width: 60px;
  max-width: 60px;
  font-family: 'BruelGroteska-Bold', sans-serif;
}

.scoreHome {
  padding-right: 20px;
}

.scoreAway {
  padding-left: 20px;
}
