/* PlayerSticker.css */
@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Narrow&display=swap');

.playerSticker {
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  min-width: 100px; /* Set a minimum width */
  height: auto; /* Allow height to adjust based on content */
  border-radius: 8px;
  padding: 5px; /* Added padding */
  margin-bottom: 10px; /* Added margin for separation */

  /* Other styling properties */
}

.playerInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center; /* Center align text */
}

.playerName {
  font-size: 0.8rem;
  padding-top: 3px;
  white-space: nowrap; /* Prevents text wrapping */
  overflow: hidden; /* Hides overflowing text */
  text-overflow: ellipsis; /* Displays ellipsis for truncated text */
  text-align: center;
  font-family: 'PT Sans Narrow';

}

.playerPosition {
  font-size: 0.8rem;
  white-space: nowrap; /* Prevents text wrapping */
  overflow: hidden; /* Hides overflowing text */
  text-overflow: ellipsis; /* Displays ellipsis for truncated text */
  text-align: left;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}



.playerStatus {
  font-size: 0.8rem;
  padding-left: 5px;
  font-weight: bold;
}

.divider{
  font-size: 13px;
}